import {
  arrayToObjectAsync,
  getBaseProvidersData,
} from '../../contexts/main/getBaseProvidersData';
import { Pages } from '../../contexts/Location/LocationContext';
import { ControllerParams, CreateControllerFn } from '@wix/yoshi-flow-editor';
import { Challenges } from '../../editor/types/Experiments';
import { renderSeoMetatagsPage } from '../../services/SeoMetatagsPage';
import { userProviderPropsMap } from '../../contexts/User/userProviderPropsMap';
import { getChallengeInitialData } from '../../contexts/storage-contexts/Challenge';
import { Referrer } from '../../contexts/storage/referrer';
import { getProgramSlug } from '../../contexts/Location/helpers/generateLinksToProgramForList';

const createController: CreateControllerFn = async ({
  flowAPI,
}: ControllerParams) => {
  return {
    async pageReady() {
      const initialProps = {
        ...(await arrayToObjectAsync([
          getBaseProvidersData({
            flowAPI,
          }),
          getChallengeInitialData(flowAPI, Referrer.PAYMENT_PAGE),
        ])),
        ...(await arrayToObjectAsync([userProviderPropsMap(flowAPI)])),
      };

      const slug = getProgramSlug(initialProps?.challengeData?.challenge);
      const enabledSeoMetatags = flowAPI.experiments.enabled(
        Challenges.enableSeoMetatagsPayement,
      );

      if (!initialProps.participant && flowAPI.environment.isViewer) {
        initialProps.goToPage({
          pageId: Pages.Details,
          challengeId: slug,
        });
      } else {
        flowAPI.controllerConfig.setProps({ ...initialProps });

        if (enabledSeoMetatags) {
          renderSeoMetatagsPage(
            flowAPI,
            initialProps?.challengeData?.challenge,
            'PAYMENT_PAGE',
          );
        }
      }
    },
  };
};

export default createController;
